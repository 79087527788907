import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/stage.aareal-aval.de/components/form/form-immutable-data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/stage.aareal-aval.de/components/form/user-greeting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/stage.aareal-aval.de/components/mail-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/stage.aareal-aval.de/components/marketing/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/home/forge/stage.aareal-aval.de/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/node_modules/next/dist/client/image-component.js");
